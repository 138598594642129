import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma d’estrella d’1 a 3,5 cm de diàmetre, dividit en 7 a 10 lacínies. L’interior és ovoide, pedicel·lat, de color marró o grisenc. El peristoma (on es contenen les espores) és punxagut, de color més obscur, delimitat i solcat. Les espores són marrons en massa, subgloboses, berrugoses, de 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      